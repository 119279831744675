import $ from 'jquery';
import '../Components/_MenuHeader.js';
import '../Components/_Anchor.js';
import '../Components/_Slider.js';
import '../Components/_Accordion.js';

$(window).on('load', function () {
  $('.is-overlay').fadeOut(800);
});

$(function () {
  let elem = $('#js-gotop a');
  if (!elem.length) return;
  let tmp = elem.clone().attr('class', 'is-tmp').css('visibility', 'hidden');
  let parent = elem.parent();
  document.addEventListener('scroll', function () {
    let scrollTop = $(this).scrollTop();
    let parentTop = parent.offset().top;
    let parentHeight = parent.outerHeight();
    let bottom = 20;
    if ($(window).width() <= 767) {
      bottom = 10;
    }
    if (scrollTop > 300) {
      parent.append(tmp);
      elem.addClass('is-show').css({ position: 'fixed', bottom: bottom });
      if (
        scrollTop >=
        parentTop - window.innerHeight + (parentHeight + bottom)
      ) {
        tmp.css({ visibility: '' });
        elem.css({ visibility: 'hidden' });
      } else {
        tmp.css({ visibility: 'hidden' });
        elem.css({ visibility: '' });
      }
    } else {
      parent.find('.is-tmp').remove();
      elem.removeClass('is-show');
    }
  });
});

$(function () {
  $(document).on('click', '#js-gotop a', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 800);
  });
});

$(window).on('load scroll', function () {
  const getOffsetTop = function (elem) {
    let offsetTop = elem.offsetTop;
    if (elem.offsetParent) {
      offsetTop += getOffsetTop(elem.offsetParent);
    }
    return offsetTop;
  };

  $('.js-title1').each(function (i) {
    let offTop = getOffsetTop(this);
    let idAttr = $(this).attr('id');
    let heightttl = $(this).height();
    if (idAttr) {
      if ($(window).scrollTop() >= offTop - heightttl) {
        $('.js-title1-link').removeClass('is-current');
        $('.js-title1-link[href="#' + idAttr + '"]').addClass('is-current');
      }
    }
  });
});

$(function () {
  $('.js-slideToggle').on('click', function () {
    $(this).toggleClass('is-active');
    $(this).parent().toggleClass('is-active');
    $(this).parent().next('.js-slideToggle-item').slideToggle();
  });
});
