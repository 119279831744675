import $ from 'jquery';
import Swiper from 'swiper/bundle';

function numStartZero(num) {
  return parseInt(num) < 10 ? '0' + num : num;
}

$(function () {
  $('.js-mv1-inner').each(function () {
    const slide = $('.js-mv1-swiper', this);
    const inits = $('.js-mv1-init', this);
    if (slide.length) {
      new Swiper(slide.get(0), {
        loop: true,
        effect: 'fade',
        slidesPerView: 1,
        speed: 2000,
        allowTouchMove: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        on: {
          init: function () {
            inits.addClass('mv-loaded');
            slide.addClass('mv-loaded');
          },
        },
      });
    }
  });
});

$(function () {
  $('.js-slider1-inner').each(function () {
    const slide = $('.js-slider1-swiper', this);
    const item = $('.js-slider1-item', this);
    const total = $('.js-slider1-total', this);
    if (slide.length) {
      new Swiper(slide.get(0), {
        loop: true,
        effect: 'fade',
        slidesPerView: 1,
        speed: 2000,
        autoplay: false,
        loopPreventsSlide: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        runCallbacksOnInit: true,
        on: {
          init: function (sw) {
            item.text(numStartZero(sw.realIndex + 1));
            total.text(
              numStartZero($(sw.slides).not('.swiper-slide-duplicate').length),
            );
          },
          slideChange: function (sw) {
            item.text(numStartZero(sw.realIndex + 1));
          },
        },
      });
    }
  });
});

$(function () {
  $('.js-slider2-swiper').each(function () {
    new Swiper(this, {
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 26,
      breakpoints: {
        768: {
          spaceBetween: 28,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  });
});

const slider3 = new Swiper('.js-slider3-swiper', {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 27,
  breakpoints: {
    768: {
      slidesPerView: 2.5,
      spaceBetween: 20,
    },
  },
  navigation: {
    prevEl: '.swiper-button-prev',
    nextEl: '.swiper-button-next',
  },
});
