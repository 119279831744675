import $ from 'jquery';

$('a[href*=\\#]:not([href=\\#])').on('click', function () {
  if (
    location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
    location.hostname === this.hostname
  ) {
    let $target = $(this.hash);
    $target =
      ($target.length && $target) || $('[name=' + this.hash.slice(1) + ']');
    let offsetTop = 0;
    if ($(window).width() < 768) {
      offsetTop = $('.c-header').height();
    }
    if ($target.length) {
      let targetOffset = $target.offset().top - offsetTop;
      $('html,body').animate({ scrollTop: targetOffset }, 1000);
      return false;
    }
  }
});

$('.js-tab1-btn').on('click', function (e) {
  e.preventDefault();
  var tabId = $(this).attr('data-tab');
  $(this).closest('.c-tabs1').find('a').removeClass('is-current');
  $('.c-tabs1__inner1').removeClass('is-current');
  $(this).addClass('is-current');
  $('#' + tabId).addClass('is-current');
})
