import $ from 'jquery';

const btnAccor1 = $('.js-accordion1'),
  contentAccor1 = $('.c-accordion1__answer1'),
  closeAccor1 = $('.js-close-accor1');

const anchorToggle = $('.js-anchor-toggle');

contentAccor1.hide();

btnAccor1.on('click', function () {
  $(this).toggleClass('is-open');
  $(this).find('.c-icon1').toggleClass('is-open');
  $(this).next().slideToggle();
});

closeAccor1.on('click', function () {
  $(this).parent('.c-accordion1__answer1').slideUp();
  $(this).parent().parent().find('.c-icon1').removeClass('is-open');
});

anchorToggle.on('click', function (e) {
  var toggleID = $(this).attr('href');
  if (toggleID != "#") {
    setTimeout(function () {
      $('.c-accordion1__item1' + toggleID).find('.c-accordion1__answer1').slideDown();
      $('.c-accordion1__item1' + toggleID).find('.c-icon1').toggleClass('is-open');
    }, 1000);
  }
  e.preventDefault;
});
