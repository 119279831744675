import $ from 'jquery';

const body = $('body');
const wind = $(window);
const bodyHTML = $('body, html');
/* ======================================
Header
====================================== */
let scrollPosi;

function bodyFix() {
    scrollPosi = $(window).scrollTop();

    body.addClass('is-fixed').css({top: -scrollPosi});
}

function bodyFixReset() {
    body.removeClass('is-fixed').css({top: '0'});

    bodyHTML.scrollTop(scrollPosi);
}

function resetMenuHeader() {
    $('.js-menu').removeClass('is-active');
    $('.c-header__content').removeClass('is-active');
    $('.c-header').removeClass('is-menu-open');
    bodyFixReset();
}

(() => {
    const btn = $('.js-menu');
    const content = $('.c-header__content');
    const btnSubMenu = $('.js-show-menuSub');

    wind.on('load resize', function () {
        setTimeout(
            () =>
                wind.outerWidth() < 768 && content.css('transition', '.3s ease-in-out'),
            500,
        );

        wind.outerWidth() > 767 && content.removeAttr('style');
    });

    if (btn.length) {
        btn.on('click', function () {
            const header = $(this).parents('.c-header');
            const header_content = header.find('.c-header__content');

            header.toggleClass('is-menu-open');
            header_content.toggleClass('is-active');

            if (header_content.hasClass('is-active')) {
                bodyFix();
                $(this).removeClass('is-close').addClass('is-active');
            } else {
                bodyFixReset();
                $(this).removeClass('is-active').addClass('is-close');
            }
        });

        $(window).bind('pageshow', function (event) {
            if (event.originalEvent.persisted) {
                $('.c-header__content').addClass('is-hide');
                resetMenuHeader();

                setTimeout(() => {
                    $('.c-header__content').removeClass('is-hide');
                }, 0);
            }
        });
    }

    btnSubMenu.on('click', function () {
        $(this).toggleClass('is-active');
        $(this).next().slideToggle(300);
    });
})();
